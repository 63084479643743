@import '../../../assets/themes/styles/variables.scss';

.appButton {
  &:disabled {
    opacity: 0.4;
    background-color: $second;
    color: white;
  }
}

:global {
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    opacity: 0.4;
    background-color: $second;
    color: white;
  }

  .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    opacity: 0.4;
    color: $second;
    border-color: $second;
  }
}
