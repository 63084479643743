@font-face {
  font-family: 'Noto-Regular';
  src: url(./../fonts/Noto/NotoSansThai-Regular.otf) format('OpenType');
  font-weight: 400px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto-Medium';
  src: url(./../fonts/Noto/NotoSansThai-SemiBold.otf) format('OpenType');
  font-weight: 600px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto-Bold';
  src: url(./../fonts/Noto/NotoSansThai-Bold.otf) format('OpenType');
  font-weight: 700px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular-Book';
  src: url(./../fonts/Circular/FWDCircularTT-Book.woff) format('woff'),
    url(./../fonts/Circular/FWDCircularTT-Book.otf) format('OpenType'),
    url(./../fonts/Circular/FWDCircularTT-Book.ttf) format('truetype');
  font-weight: 400px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url(./../fonts/Circular/FWDCircularTT-Medium.woff) format('woff'),
    url(./../fonts/Circular/FWDCircularTT-Medium.otf) format('OpenType'),
    url(./../fonts/Circular/FWDCircularTT-Medium.ttf) format('truetype');
  font-weight: 600px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular-Bold';
  src: url(./../fonts/Circular/FWDCircularTT-Bold.woff) format('woff'),
    url(./../fonts/Circular/FWDCircularTT-Bold.otf) format('OpenType'),
    url(./../fonts/Circular/FWDCircularTT-Bold.ttf) format('truetype');
  font-weight: 700px;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url('./../fonts/password.ttf');
}
