.info {
  @apply tw-bg-primary tw-rounded-16px;
  .headOfInfo {
    @apply tw-bg-primary tw-p-4 tw-text-white tw-flex tw-items-center tw-space-x-2 tw-rounded-16px;
  }
  .label {
    @apply tw-text-13px tw-font-noto-regular tw-text-white tw-break-words;
  }
  .text {
    @apply tw-text-13px tw-font-circular-bold tw-font-bold tw-text-white tw-break-words;
  }
}

.headOfReport {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px 0 10px;
  border-bottom: 1px solid #f5f3f6;
}

.bodyOfReport {
  padding: 10px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

@media only screen and (min-width: 768px) {
  :global {
    .ant-modal {
      max-width: 395px;
    }
  }
}
