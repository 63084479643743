.tabletHeader {
  min-height: 56px;
  border-bottom-left-radius: 16px;
}

.FWDImg {
  content: url('../../assets/images/FWD-Nospace.svg');
}
.SCBImg {
  content: url('../../assets/images/ScbLogo.svg');
  margin-right: 16px;
}

.wrapper {
  overflow: hidden scroll;
  height: 100vh;
}

.headerDefault {
  height: calc(100vh - 56px);
}

@media only screen and (min-width: 768px) {
  .tabletLogin {
    background-image: url('../../assets/images/login_bg.png');
    background-size: cover;
  }

  .tabletHeader {
    min-height: 98px;
    border-bottom-left-radius: 32px;
  }

  .tabletHeaderContent {
    height: 98px;
  }

  .FWDImg {
    content: url('../../assets/images/icons/FWDLogoTablet.svg');
    padding-right: 16px;
  }
  .SCBImg {
    content: url('../../assets/images/icons/ScbLogoTablet.svg');
    margin-right: 29px;
  }

  .childrenWraper {
    width: calc(100% - 335px);
    max-width: calc(100% - 335px);
    position: absolute;
    margin-left: 100px;
    padding-left: 16px;
  }

  .haveBorder {
    border-right: 1px solid rgba(51, 51, 51, 0.5);
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .headerDefault {
    height: calc(100vh - 98px);
  }
}
