/* config css */
:root {
  --tw-color-primary: #4f2a81;
  --tw-color-second: #e87722;
  --tw-color-text: #333333;
  --tw-color-text-error: #a90c0b;
  --tw-color-border-default: #e5e5e5;
  --tw-color-shadow-default: rgba(#808080, 0.12);
}
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Noto-Regular', 'Circular-Book', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background-color: #f5f3f6;
  font-size: 13px;
}
