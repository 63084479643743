/* Reset Button */
@mixin reset-btn {
  background-color: transparent !important;
  color: inherit !important;
  &:hover {
    color: $primary !important;
  }
}

/* Border Radius */
@mixin border-radius($radio) {
  -webkit-border-radius: $radio;
  -moz-border-radius: $radio;
  -ms-border-radius: $radio;
  -o-border-radius: $radio;
  border-radius: $radio;
}

/* Transition */
@mixin transition($trans) {
  -webkit-transition: $trans;
  -moz-transition: $trans;
  -ms-transition: $trans;
  -o-transition: $trans;
  transition: $trans;
}

/* Transform */
@mixin transform($trans) {
  -webkit-transform: $trans;
  -moz-transform: $trans;
  -ms-transform: $trans;
  -o-transform: $trans;
  transform: $trans;
}

/* TYP0 */
@mixin typo-size($size, $leading) {
  font-size: $size;
  line-height: $leading;
}
.scb-heading-1,
%heading-1 {
  @include typo-size(72px, 88px);
}

.scb-heading-1,
%heading-2 {
  @include typo-size(56px, 64px);
}

.scb-heading-3,
%heading-3 {
  @include typo-size(48px, 56px);
}

.scb-heading-4,
%heading-4 {
  @include typo-size(32px, 40px);
}

.scb-heading-5,
%heading-5 {
  @include typo-size(24px, 32px);
}

.scb-heading-6,
%heading-6 {
  @include typo-size(20px, 24px);
}

.scb-text-large,
%text-large {
  @include typo-size(18px, 22px);
}

.scb-text-medium,
%text-medium {
  @include typo-size(16px, 22px);
}

.scb-text-normal,
%text-normal {
  @include typo-size(14px, 20px);
}

.scb-text-small,
%text-small {
  @include typo-size(13px, 20px);
}

.scb-caption,
%caption {
  @include typo-size(12px, 15px);
}
