/* Colors */
$white: white;
$black: black;

$primary: #4F2A81;
$second: #E87722;
// $third: #6fb64c;
// $four: #f43c43;
// $five: #464a88;
// $six: #fa676d;

// $purple: #9688f3;
// $purple-2: #b23fcf;
// $pink: #eb7dbf;
// $orange: #f79a36;

// $primary-blur: rgba($primary, 0.08);
// $second-blur: rgba($second, 0.08);
// $third-blur: rgba($third, 0.08);
// $four-blur: rgba($four, 0.08);

$error-color: #a90c0b;
// $success-color: #00875a; 

$text-color: #333333;
// $text-gray: #767676;

// $text-blur: rgba($text-color, 0.06);
// $text-blur-8: rgba($text-color, 0.08);
// $text-blur-12: rgba($text-color, 0.12);
// $text-blur-20: rgba($text-color, 0.2);
// $text-blur-50: rgba($text-color, 0.5);
// $text-blur-75: rgba($text-color, 0.75);

// $focusInputColor: #5bc5f2;

$gray1: #f9f9f9;
$gray2: #eef2f4;
$gray3: #e5e8ed;
$gray4: #eaeff2;

$border-color-default: #E5E5E5;
$shadow-color-default: rgba(#808080, 0.12);

/*Font Weight*/
$bold: 700;
$semibold: 600;
$medium: 600;
$regular: 600;

$radius-default: 4px;
/* Mixin */
@import 'mixins.scss';
