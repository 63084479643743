@import './variables.scss';

.ant-input-lg,
.ant-input-number-lg input {
  min-height: 50px;
}

.ant-input-affix-wrapper-lg {
  padding: 4px 11px;
}

.ant-input,
.ant-input-number {
  border: 2px solid #e5e5e5;
}

.ant-modal-content {
  @apply tw-rounded-2xl;
}

.ant-modal-mask {
  background-color: rgba(24, 48, 40, 0.9);
}

.ant-form-item-label > label {
  @apply tw-text-base;
  @apply tw-font-scb-medium;
  &.ant-form-item-required {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    &::before {
      margin-left: 3px;
    }
  }
}

.ant-form-item-label > label::after {
  display: none;
}

.ant-radio-inner {
  border-color: #4f2a81 !important;
  background-color: transparent;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #4f2a81;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4f2a81;
  border-color: #4f2a81;
}

.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
  .ant-checkbox-inner {
    background-color: #f5f3f6;
    border-color: #f5f3f6;
  }
}

.disabledForm {
  pointer-events: none;
  opacity: 0.4;
}
